.title {
  &.line20 {
    line-height: 2;
  }
  &.letter0 {
    letter-spacing: 0;
  }
  &.arrow {
    &::after {
      content: " ";
      display: block;
      position: absolute;
      bottom: -24px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 24px 0 24px;
      border-color: $maincolor transparent transparent transparent;
    }
    padding: 19px 0;
    position: relative;
    @include breakpoint(small only){
      &::after {
        bottom: -18px;
        border-width: 18px 18px 0 18px;
      }
      padding: 8px 0;
    }

  }
  &.heading {
    .eng {
      display: block;
      margin: 5px auto 0;
      @extend .regular;
    }
    .img {
      @include breakpoint(small only){
        display: block;
        transform: scale(.5);
        margin: 0 auto 20px;
      }
      @include breakpoint(medium){

      }
      margin-right: 30px;
      vertical-align: middle;
    }
    @extend .bold;
  }

  letter-spacing: 3px;
  line-height: 1;
}

.list.news {
  .single {
    .category {
      color: $white;
      height: 24px;
      line-height: 24px;
      margin: 0 30px 0 16px;
      text-align: center;
      width: 95px;
      width: 120px;
    }
    .content {
      max-width: 740px;
      max-width: 700px;
    }
    @include breakpoint(small only){
      .content {
        margin: 7px 0 0;
        width: 100%;
      }
      position: relative;
      &::after {
        content: "＞";
        display: block;
        position: absolute;
        bottom: 1.6em;
        right: 0;
      }
    }
    @include breakpoint(medium){
      position: relative;
      &::after {
        content: "＞";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #ccc;
  }
  .detail {
    .category {
      color: $white;
      height: 24px;
      line-height: 24px;
      margin: 0 30px 0 16px;
      text-align: center;
      width: 120px;
    }
    .content {
      max-width: 700px;
    }
    &:first-child {
      border-top: 1px solid #ccc;
      padding-top: 18px;
    }
    @include breakpoint(small only){
      .content {
        width: 100%;
      }
    }
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #ccc;
  }
  &.pages {
    @include breakpoint(small only){
      .detail {
        position: relative;
        &::after {
          content: "＞";
          display: block;
          position: absolute;
          bottom: 1.6em;
          right: 0;
        }
      }
    }

    @include breakpoint(small){
      .date {
        margin: 0 15px 0 0;
      }
      .category {
        margin: 0 0 0 0;
      }
    }
    @include breakpoint(medium){
      .date {
        margin: 0 27px 0 70px;
      }
      .category {
        margin: 0 46px 0 27px;
      }
    }
  }
}
.btn {
  a {
    border-radius: 4px;
    display: block;
    line-height: 1;
    text-align: center;
    width: 100%;
  }
  // default
  @include breakpoint(small){
    width: 205px;
  }
  @include breakpoint(medium){
    width: 250px;
  }

  &.orange a {
    background: #fc8b4b;
    border: 1px solid #fc8b4b;
    padding: 17px 0;
    color: $white;
    width: 205px;
    @include breakpoint(medium){
      &:hover {
        background: $white;
        color: #fc8b4b;
      }
      width: 220px;
    }
  }
  &.custom200 {
    @include breakpoint(small){
      width: 48%;
    }
    @include breakpoint(medium){
      width: 200px;
    }
  }
  &.custom250 {
    @include breakpoint(small){
      width: 48%;
    }
    @include breakpoint(medium){
      width: 250px;
    }
  }


}
a {
  &.back-main-hover {
    &:hover {
      background: $white;
      color: $maincolor;
    }
    background: $maincolor;
    border: 1px solid $maincolor;
    padding: 17px 0;
    color: $white;
  }
  &.back-clear-hover {
    &:hover {
      background: $maincolor;
      color: $white;
    }
    border: 1px solid $maincolor;
    padding: 17px 0;
    color: $maincolor;
  }
  &.back-all-hover {
    &:hover, &.active {
      background: $maincolor;
      color: $white;
    }
    background: none;
    border: 1px solid $maincolor;
    padding: 17px 0;
    color: $maincolor;
  }
  &.back-green-hover {
    &:hover, &.active {
      background: #4a8f3c;
      color: $white;
    }
    background: none;
    border: 1px solid #4a8f3c;
    padding: 17px 0;
    color: #4a8f3c;
  }
  &.back-red-hover {
    &:hover, &.active {
      background: #e15d5d;
      color: $white;
    }
    background: none;
    border: 1px solid #e15d5d;
    padding: 17px 0;
    color: #e15d5d;
  }
  &.back-blue-hover {
    &:hover, &.active {
      background: #4c7cc8;
      color: $white;
    }
    background: none;
    border: 1px solid #4c7cc8;
    padding: 17px 0;
    color: #4c7cc8;
  }
  &.back-yellow-hover {
    &:hover, &.active {
      background: #eba533;
      color: $white;
    }
    background: none;
    border: 1px solid #eba533;
    padding: 17px 0;
    color: #eba533;
  }
}

.border-top {
  border-top: 1px solid $maincolor;
}
.border-bottom {
  border-bottom: 1px solid $maincolor;
}
.border-top-small {
  @include breakpoint(small only){
    border-top: 1px solid $maincolor;
  }
}
.border-top-medium {
  @include breakpoint(medium){
    border-top: 1px solid $maincolor;
  }
}
.border-en {
  &:last-child {
    border-bottom: 1px solid #BBCBFD;
  }
  border-top: 1px solid #BBCBFD;
}

.cards {
  a {
    &:hover {
      background: rgba(216, 216, 216, 0.6);
    }
    display: block;
    color: inherit;
    transition: background .3s ease;
  }

  &::after, &::before {
    content: "";
//    height: 1px;
    display: block;
    width: 270px;
    order: 1;
  }
  &.narrow {
    &::after, &::before {
      content: "";
      width: 190px;
      order: 1;
    }
    .img {
      display: block;
      padding: 10px 10px 5px;
    }
    @include breakpoint(medium){
      .content {
        padding: 0 10px 20px;
      }
      .single {
        width: 190px;
      }
    }
  }

  @include breakpoint(small){
    .single {
      background: $white;
      box-shadow: 1px 1px 3px #ccc, -1px -1px 3px #ccc;
      margin-bottom: 20px;
      width: 135px;
      width: 48%;
    }
    .content {
      padding: 10px 12px;
      .text {
        margin: 5px 0 15px;
      }
      .category {
        .parent {
          margin: 0;
          padding: 0px 12px;
        }
        .child {
          width: 100%;
        }
      }
    }
  }
  @include breakpoint(medium){
    .single {
      margin-bottom: 40px;
      width: 270px;
    }
    .liqid {
      width: 19%;
    }
    .content {
      padding: 20px 16px;
      .category {
        .parent {
          margin: 0 30px 0 0;
          padding: 0px 23px;
        }
        .child {
          width: auto;
        }
      }
    }
  }
}

.circle {
  border-radius: 50%;
  width: 210px;
  height: 210px;
  background-image: linear-gradient(to top, #f2a0a0, #ffecec);
}


// 詳細ページの情報部分
.property {
  .date {
    margin: 0 20px 0 0;
    line-height: 24px;
  }
  .category {
    line-height: 24px;
    text-align: center;
    padding-left: 6px;
    padding-right: 6px;
//    max-width: 140px;
  }
  .title {
    line-height: 1.2;
    letter-spacing: 0;
    margin: 0;
    padding: 5px 0 10px;
    width: 100%;
  }
}
.frame {
  .title {
    border-bottom: 1px solid #e6e6e6;
  }
  border-left: 8px solid $maincolor;
  padding-left: 12px;
}


.manufacturer {
  .items {
    @include breakpoint(small){

    }
    @include breakpoint(medium){
      &:first-child {
        margin: 0 40px 0 0;
      }
      &:last-child {
        margin: 0 0 0 40px;
      }
      &:last-child:first-child {
        margin: 0;
      }
      margin: 0 40px;
    }

  }
}
// /manufacturer

.sidebar {
  .title {
    @include breakpoint(small){

    }
    @include breakpoint(medium){
      padding: 0 0 10px 30px;
    }
  }
  .cat-parent {
    &:last-child, &.toggle {
      border-bottom: 1px solid #dfdfdf;
    }
    border-top: 1px solid #dfdfdf;
    padding: 12px 0 12px 30px;
    margin: 0;
  }
  .cat-child {
    &:not(:last-child) {
      border-bottom: 1px solid #f5f5f5;
    }
    padding: 8px 0 8px 42px;
    margin: 0;
  }
}
// /sidebar


.contact {
  @include breakpoint(small){
    .single {
      &:last-child {
        border-bottom: 1px solid #dcdcdc;
      }
      line-height: 1;
      border-top: 1px solid #dcdcdc;
      padding: 0.5rem 0;
      width: 100%;
    }
    .inner {
      @extend .flex;
    }
    .name {
      margin-right: 10px;
    }
    .must {
      color: #bb4444;
    }
    .input {
      width: 100%;
    }
  }
  @include breakpoint(medium){
    .single {
      padding: 15px 0;
    }
    .inner {
      margin: 0 auto;
      width: 700px;
    }
    .name {
      width: 200px;
    }
  }

  width: 100%;
}
// /conatct

.thumb {
  &.default {
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: auto;
      height: auto;
    }
    position: relative;
    height: 150px;
    @include breakpoint(small only){
      height: 75px;
    }
  }
  &.mini {
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: auto;
      height: auto;
    }
    position: relative;
    height: 110px;
    @include breakpoint(small only){
      height: 75px;
    }
  }
}
.eyecatch img {
  @include breakpoint(small only){
    max-height: 105px;
  }
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 210px;
  width: auto;
}
.imagelink a {
  height: 100%;
  width: 100%;
  display: block;
}

