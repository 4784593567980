// font-size
@mixin fontSize($smallSize,$mediumSize,$largeSize){
  @include breakpoint(small){
    font-size: $smallSize;
  }
  @include breakpoint(medium){
    font-size: $mediumSize;
  }
  @include breakpoint(large){
    font-size: $largeSize;
  }
}
.h1 { @include fontSize(24px,48px,48px); }
.h2 { @include fontSize(20px,40px,40px); }
.h3 { @include fontSize(19px,31px,31px); }
.h4 { @include fontSize(18px,25px,25px); }
.h5 { @include fontSize(17px,20px,20px); }
.h6 { @include fontSize(16px,16px,16px); }
.s7 { @include fontSize(13px,13px,13px); }
.s8 { @include fontSize(11px,11px,11px); }
.s9 { @include fontSize(9px,9px,9px); }

.f60 { @include fontSize(30px,30px,60px); }
.f40 { @include fontSize(15px,15px,40px); }
.f38 { @include fontSize(16px,16px,38px); }
.f35 { @include fontSize(17.5px,17.5px,35px); }
.f32 { @include fontSize(16px,16px,32px); }
.f30 { @include fontSize(20px,20px,30px); }
.f24 { @include fontSize(12px,12px,24px); }
.f22 { @include fontSize(15px,15px,22px); }
.f20 { @include fontSize(12px,12px,20px); }
.f18 { @include fontSize(12px,12px,18px); }
.f16 { @include fontSize(12px,12px,16px); }
.f14 { @include fontSize(12px,12px,14px); }
.f13 { @include fontSize(11.5px,11.5px,13px); }
.f12 { @include fontSize(12px,12px,12px); }
.f11 { @include fontSize(11px,11px,11px); }
.f10 { @include fontSize(11px,11px,10px); }

.f24sp15 { @include fontSize(15px,15px,24px); }
.f22sp13 { @include fontSize(13px,13px,22px); }
.f22sp12 { @include fontSize(12px,12px,22px); }
.f20sp15 { @include fontSize(15px,15px,20px); }
.f18sp14 { @include fontSize(14px,14px,18px); }
.f16sp14 { @include fontSize(14px,14px,16px); }
.f16sp13 { @include fontSize(13px,13px,16px); }
.f11sp12 { @include fontSize(12px,12px,11px); }
.f10sp11 { @include fontSize(11px,11px,10px); }
.f12sp10 { @include fontSize(10px,10px,12px); }


h2 { @include fontSize(20px,24px,24px); }
h3 { @include fontSize(19px,20px,20px); }


// line-height
.line10 { line-height: 1; }
.line15 { line-height: 1.5; }
.line18 { line-height: 1.8; }
.line20 { line-height: 2; }


.line20-autop {
  p { line-height: 2; }
  table {
    th,td {
      border: 1px solid #ccc;
      padding: 2%;
    }
  }
  min-height: 8em;
}

// font-weight
.bold {
  font-weight: 600;
}
.regular {
  font-weight: 400;
}

// text-align
.text-center-small {
  @include breakpoint(small only){
    text-align: center;
  }
  @include breakpoint(medium){

  }
  @include breakpoint(large){

  }
}
.text-left-small {
  @include breakpoint(small only){
    text-align: left;
  }
  @include breakpoint(medium){

  }
}

// color
.color-inherit { color: inherit; }
.color-main { color: $maincolor; }
.color-dark { color: $maincolor-dark; }
.color-white { color: $white; }
.color-black { color: $black; }

.color-green { color: $green; }
.color-time { color: #8e8e8e; }

.back-white { background: $white; }
.back-black { background: $black; }
.back-gray { background: $gray; }
.back-main { background: $maincolor; }
.back-light { background: $maincolor-light; }
.back-main-clear { background: $maincolor-clear; }
.back-clear { background: $clear; }

.back-green {
  background: $green;
  border: 1px solid $green;
  color: $white;
}
.back-red {
  background: #e15d5d;
  border: 1px solid #e15d5d;
  color: $white;
}
.back-blue {
  background: #4c7cc8;
  border: 1px solid #4c7cc8;
  color: $white;
}
.back-yellow {
  background: #eba533;
  border: 1px solid #eba533;
  color: $white;
}
.back-green-light {
  background: #67bf4b;
  border: 1px solid #67bf4b;
  color: $white;
}

@mixin colorcat($code){
  background: $code;
  border: 1px solid $code;
  color: $white;
  transition: background .3s ease;
}
@mixin colorcat-linked($code){
  &:hover,&.active {
    background: $code;
    color: $white;
  }
  background: $white;
  border: 1px solid $code;
  color: $code;
  transition: background .3s ease;
  @include breakpoint(small){
    padding: 12px 0;
  }
  @include breakpoint(medium){
    padding: 17px 0;
  }
}

.blog-nav-back {
  &.all { @include colorcat-linked($maincolor); }

//  &.technology { @include colorcat-linked(#4c7cc8); }
//  &.product { @include colorcat-linked(#67bf4b); }
  &.technology { @include colorcat-linked(#67bf4b); }
  &.product { @include colorcat-linked(#4c7cc8); }

  &.trend { @include colorcat-linked(#e14f4f); }
  &.staff { @include colorcat-linked(#eba533); }
}
.blog-single-cat {
//  &.technology { @include colorcat(#67bf4b); }
//  &.product { @include colorcat(#4c9fcb); }

  &.technology { @include colorcat(#67bf4b); }
  &.product { @include colorcat(#4c9fcb); }

  &.trend { @include colorcat(#e14f4f); }
  &.staff { @include colorcat(#eba533); }
}

.news-nav-back {
  &.news { @include colorcat-linked(#4a8f3c); }
  &.seminar { @include colorcat-linked(#e15d5d); }
  &.event { @include colorcat-linked(#4c7ccb); }
}
.news-single-cat {
  &.news { @include colorcat(#49903b); }
  &.seminar { @include colorcat(#e15d5d); }
  &.event { @include colorcat(#4c7ccb); }
}

.column-nav-back {
  &.technology { @include colorcat-linked(#4a8f3c); }
  &.trend { @include colorcat-linked(#e15d5d); }
  &.technical-labo { @include colorcat-linked(#4c7ccb); }
}
.column-single-cat {
  &.technology { @include colorcat(#49903b); }
  &.trend { @include colorcat(#e15d5d); }
  &.technical-labo { @include colorcat(#4c7ccb); }
}


// layout width
@mixin width($smallSize,$mediumSize,$largeSize){
  @include breakpoint(small){
    width: $smallSize;
  }
  @include breakpoint(medium){
//    width: $mediumSize;
    width: $largeSize;
  }
  @include breakpoint(large){
    width: $largeSize;
  }
}
.container {
  .container {
    @include breakpoint(medium down){
      padding: 0;
    }
  }
  &.expanded {
    @include width(100%,100%,100%);
    @include breakpoint(medium down){
      padding: 0;
    }
  }
  &.wide {
    @include breakpoint(small){
      width: 100%;
      max-width: 1385px;
    }
    @include breakpoint(medium){
      width: 100%;
      max-width: 1385px;
    }
    @include breakpoint(large){
      width: 100%;
      max-width: 1385px;
    }
  }
  &.narrow {
    @include width(100%,100%,$widthNarrow);
  }
  &.sidebar {
    @include width(100%,100%,240px);
  }

  &.custom1120 {
    @include width(100%,100%,1120px);
  }
  &.custom1090 {
    @include width(100%,100%,1090px);
  }
  &.custom1060 {
    @include width(100%,100%,1060px);
  }
  &.custom1044 {
    @include width(100%,100%,1044px);
  }
  &.custom1020 {
    @include width(100%,100%,1020px);
  }
  &.custom1000 {
    @include width(100%,100%,1000px);
  }
  &.custom820 {
    @include width(100%,100%,820px);
  }
  &.custom880 {
    @include width(100%,100%,880px);
  }
  &.custom725 {
    @include width(100%,100%,725px);
  }
  &.custom605 {
    @include width(100%,100%,605px);
  }
  &.main-group {
    @include breakpoint(small only){
      br {
        display: none;
      }
      padding: 0 40px;
    }
    @include breakpoint(xlarge){
      padding: 0;
    }
    @include breakpoint(xxlarge){
//      padding: 0;
    }
  }

  @include width(100%,100%,$widthDefault);

  @include breakpoint(small only){
    padding: 0 20px;
  }
  margin: 0 auto;
}
.framed-2-small {
  @include breakpoint(small only){
    padding: 0 20px;
  }
}
.framed {
  &.custom470 {
    width: 470px;
  }
}


// layout margin
.l-content {
  &.back-on {
    @include breakpoint(small only){
      padding: $contents/2 0;
    }
    padding: $contents 0;
    margin: 0 auto;
  }
  &.back-high {
    @include breakpoint(small only){
      padding: ($contents +20)/2 0;
    }
    padding: ($contents +20) 0;
    margin: 0 auto;
  }
  &.high {
    @include breakpoint(small only){
      margin: ($contents +20)/2 0;
    }
    margin: ($contents +20) auto 0;
  }
  &.half {
    @include breakpoint(small only){
      margin: $contents/4 auto 0;
    }
    margin: ($contents/2)+5 auto 0;
  }
  &.top {
    @include breakpoint(small only){
      padding: 1px 0;
      margin: 0 auto;
    }
  }
  &.bottom {
    @include breakpoint(small only){
      margin: $contents/2 auto;
    }
    margin: $contents auto;
  }
  @include breakpoint(small only){
    margin: $contents/2 auto 0;
  }

  margin: $contents auto 0;
}
.l-half {
  @include breakpoint(small){
    margin: 0 auto $contents/4;
  }
  @include breakpoint(medium){
    margin: 0 auto $contents/2;
  }
  @include breakpoint(large){

  }
}
.l-center {
  margin: 0 auto;
}
.l-center-small {
  @include breakpoint(small only){
    margin: 0 auto;
  }
}
.l-caption {
  margin: 0;
}
.l-half-bottom {
  margin-bottom: $contents/2;
}
.l-half-bottom-small {
  @include breakpoint(small only){
    padding-bottom: $contents/2;
  }
}
.l-half-minus {
  @include breakpoint(small){
    margin-bottom: -$contents/4;
  }
  @include breakpoint(medium){
    margin-bottom: -$contents/2;
  }
}

.l-inner {
  @include breakpoint(small){
    margin: 0 auto $contentsInner/2;
  }
  @include breakpoint(medium){
    margin: 0 auto $contentsInner;
  }
}
.l-inner-small {
  @include breakpoint(small only){
    margin-bottom: $contentsInner;
  }
}
.l-second {
  @include breakpoint(small){
    margin: 0 auto 12px;
  }
  @include breakpoint(medium){
    margin: 0 auto 24px;
  }
}
.l-mini {
  &.no-small {
    margin-bottom: 0;
  }
  margin-bottom: $contentsMini;
}
.l-mini-small {
  @include breakpoint(small only){
    margin-bottom: $contentsMini;
  }
}
.l-half-small {
  @include breakpoint(small only){
    margin-bottom: $contents/4;
  }
}


.l-title {
  @include breakpoint(small only){
    margin: 0 auto $contents/4;
  }
  margin: 0 auto ($contents/2)+5;
}
.l-margin1em {
  margin: 0 1em;
}
.nega-bottom-half {
  @include breakpoint(small){
    margin-bottom: -15px;
  }
  @include breakpoint(medium){
    margin-bottom: -30px;
  }
}

// layout iblock
.l-iblock {
  .li {
    display: inline-block;
  }
}

// layout flex
.row {
  &.flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction:         row-reverse;
  }
}
.flex {
  &.flex-reverse {
    -webkit-flex-direction: row-reverse;
    flex-direction:         row-reverse;
  }
  &.align-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &.align-justify {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  &.align-center {
    webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.flex .items {
  &.slice-10 { width: 10%; }
  &.slice-6 { width: 16.666666666667%; }
  &.slice-5 { width: 20%; }
  &.slice-4 { width: 25%; }
  &.slice-3 {
    width: 33.333333%;
  }
  &.slice-2 { width: 50%; }

  @include breakpoint(small only){
    &.slice-1-small {
      width: 100%;
    }
    &.slice-2-small {
      width: 48%;
    }
  }
  @include breakpoint(medium){

  }
  @include breakpoint(large){

  }
}

// float
.floatbox {
  @include breakpoint(small){

  }
  @include breakpoint(medium){
    .left {
      float: left;
    }
    .right {
      float: right;
    }
  }
  @include breakpoint(large){

  }

}



// common class
.transition {
  transition: 0.3s ease;
}
.relative {
  position: relative;
}
.textlink {
  &.none:hover {
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  color: inherit;
}
.textlink-all {
  a {
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
    color: $black;
  }
}
.coloredlink {
  &:hover {
    text-decoration: none;
  }
  color: #1a74ff;
  text-decoration: underline;
}
.underarrow {
  &::after {
    content: " ";
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $green transparent transparent transparent;
    z-index: 2;
  }
  position: relative;
}

// common parts
.button {
  &:hover {
    color: $maincolor;
    background: none;
  }
  border: 1px solid $maincolor;
  background: $maincolor;
  color: $white;
}
.table {
  @include breakpoint(small){
    .col {
      &:last-child {
        border-bottom: 1px solid #dcdcdc;
      }
      border-top: 1px solid #dcdcdc;
      @extend .flex;
      padding: 0.5rem 0;
      width: 100%;
    }
    .th,.td {
      width: 100%;
      vertical-align: top;
    }
  }
  @include breakpoint(medium){
    &.recruit .th {
      padding-left: 40px;
    }
    .th {
      padding: 15px 0 15px 95px;
      width: 260px;
    }
    .td {
      padding: 15px 0 15px;
      width: calc(100% - 260px);
    }
  }
  @include breakpoint(large){
    .th.echo {
      padding: 15px 0 15px 55px;
    }
    .td.echo {
      padding: 15px 0 15px 60px;
    }
  }

  width: 100%;
}
.pagenation {
  @include breakpoint(small){
    a,span {
      color: inherit;
      border: 1px solid $maincolor;
      padding: 5px 12px;
      margin: 0 20px;
    }
    a:hover ,span {
      background: $maincolor;
      color: $white;
    }
    .flex {
      justify-content: space-between;
    }
  }
  @include breakpoint(medium){
    a,span {
      padding: 5px 15px;
      margin: 0 20px;
    }
    .flex {
      justify-content: center;
    }
  }
  @include breakpoint(large){
  }
  &.single {
    @include breakpoint(small){
      a,span {
        padding: 0 4px;
        margin: 0;
      }
    }
    @include breakpoint(medium){
      a,span {
        padding: 0 26px;
        margin: 0 40px;
      }
    }
    @include breakpoint(large){
    }
  }
  &.mini {
    @include breakpoint(small){
      a,span {
        padding: 5px 12px;
        margin: 0 20px;
      }
    }
    @include breakpoint(medium){
      a,span {
        padding: 5px 15px;
        margin: 0 20px;
      }
    }
    @include breakpoint(large){
    }
  }
}
.list {
  list-style: none;
  margin: 0;
}
header {
  @include breakpoint(small){
    height: $headerHsp;
  }
  @include breakpoint(medium){
    height: $headerH;
  }
  @include breakpoint(large){

  }
  position: fixed;
//  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
}
.main-visual, .sub-visual, .sub-search {
  @include breakpoint(small){
    margin-top: $headerHsp;
  }
  @include breakpoint(medium){
    margin-top: $headerH;
  }
  @include breakpoint(large){
  }
}


// link
.link {
  .color-white & {
    &:hover,&:focus {
      color: $maincolor-light;
    }
    color: $white;
  }
  &.clickable {
    &:hover {
      box-shadow: 3px 3px 10px #ccc,-3px -3px 10px #ccc;
    }
    border: 1px solid #ccc;
    color: inherit;
    display: block;
    height: 100%;
    width: 100%;

    .campaign {
      &::before {
        position: absolute;
        content: '';
        top: 100%;
        left: 0;
        border: none;
        border-bottom: solid 1em transparent;
        border-right: solid 1em $subcolor-dark;
      }
      &.sub {
        &::before {
          border-right: solid 1em $subcolor-dark;
        }
        background: $subcolor;
      }
      &.accent {
        &::before {
          border-right: solid 1em $accentcolor-dark;
        }
        background: $accentcolor;
      }
      &.main {
        &::before {
          border-right: solid 1em $maincolor-dark;
        }
        background: $maincolor;
      }
      &.pre {
        &::before {
          border-right: solid 1em #F9A825;
        }
        background: #FBC02D;
      }
      position: absolute;
      top: 1em;
      left: -1em;
      color: $white;
      background: $subcolor;
      padding: 0 1em;
    }
  }

}

// img
.half-small {
//  @include breakpoint(small only){
//    transform: scale(.5);
//  }
}

// よく使う
.indent {
  &.arrow {
    @include breakpoint(small){
      background: url("../img/arrow_right.png");
      background-repeat: no-repeat;
      background-size: 10px 10px;
      background-position: left .6em;
      text-indent: 0;
    }
    @include breakpoint(medium){
      background-size: 14px 14px;
      background-position: left .6em;
      padding-left: 24px;
    }
  }
  &.icon {
    @include breakpoint(medium){
      text-indent: -24px;
      padding-left: 24px;
    }
  }
  &.all {
    text-indent: 0;
  }
  text-indent: -1em;
  padding-left: 1em;
}
.toggleitem {
  &.active {
    display: block;
  }
  display: none;
}
.shadow {
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
}
.inpagelink {
  @include breakpoint(small){
    padding-top: $headerHsp;
    margin-top: -$headerHsp;
  }
  @include breakpoint(medium){
    padding-top: $headerH;
    margin-top: -$headerH;
  }
  @include breakpoint(large){

  }
}

.breadcrumb {
  padding-top: 6px;
}

// tips
