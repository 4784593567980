// space
$contents: 60px;
$contentsInner: 40px;
$contentsMini: 10px;

// /space

// color
$white: #ffffff;
$black: #333;

$gray: #f6f6f6;
$effect: #dfdfdf;
$shadow: #e0e0e0;
$clear: #f0f0f0;

$green: #4a8f3c;

$maincolor: #2A3A6D;
$maincolor-light: #F8F9FF;
$maincolor-clear: #F7FCFF;

$maincolor-dark: #0277a3;

$subcolor: #ef5350;
$subcolor-dark: #d32f2f;

$accentcolor: #04D09D;
$accentcolor-dark: #009688;

// width
$widthDefault: 1160px;
$widthWide: 1385px;
$widthNarrow: 850px;

// header
$headerH: 60px;
$headerHsp: 40px;


// /color
