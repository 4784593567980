.wireCatch {
  h2 {
    background: $maincolor;
    display: inline-block;
    padding: 1.2em;
  }
  margin: 1em 0;
  text-align: right;
}
.wireShadow {
  color: $white;
  text-shadow: 1px 1px 20px #000;
  margin: 0 0 30%;
}



.cs-headerGuide {
  .item {
    a {
      &:before {
        content: "■";
        color: $maincolor;
        vertical-align: middle;
        margin-right: 5px;
      }
      &:hover {
        color: $maincolor;
      }
      color: inherit;
    }
    margin: 0 20px 0 0;
  }
  line-height: 1;
}
