@include breakpoint(small only){
//@include breakpoint(medium down){
  #globalNav {
    display: none;
  }
  .globalimg {
    img {
      width: 90%;
    }
    display: inline-block;
    width: 75px;
  }
  .megamenu {
    .items {
      padding: 0 20px;
      width: 100%;
    }
    .l-caption {
      margin: 8px 0 6px;
    }
    .list {
      display: none;
    }
  }
  .maker-logo {
    .symbol {
      background: url("../img/arrow.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      float: right;
      height: 7px;
      width: 12px;
    }
  }
  .maker-single {
    margin: 10px 0 0;
    border-bottom: 1px solid rgba(42, 58, 109, 0.2);
    padding-bottom: 10px;
  }
  .globalNav {
    .logo {
      width: 33px;
    }
    .icon {
      &.active {
        .open {
          display: none;
        }
        .close {
          display: block;
        }
      }
      .open {
        display: block;
      }
      .close {
        display: none;
      }
      height: 22px;
      width: auto;
    }
  }
  .megamenu-target {
    &.active {
      .megamenu-frame {
        display: block;
      }
    }
  }
  .megamenu-frame {
    display: none;
  }
  li {
    line-height: 1.8;
  }
}
@include breakpoint(medium){
  .megamenu.container.color-black {
    padding: $contents 0 $contentsInner;
    .l-caption {
      margin: 16px 0 12px;
    }
  }

  .megamenu-target {
    .anchor {
      &::after {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 7px 0 7px;
        border-color: $white transparent transparent transparent;
        z-index: 2;
      }
      position: relative;
    }
    &:hover {
      .anchor {
        &::after {
          bottom: -28px;
          border-style: solid;
          border-width: 18px 18px 0 18px;
          border-color: $maincolor transparent transparent transparent;
        }
      }
    }
  }
  .megamenu-frame {
    &.active {
      display: block;
    }
    display: none;
    position: fixed;
    top: $headerH;
    left: 0;
    width: 100%;
    box-shadow: 0 1px 3px #8e8e8e;

    @include breakpoint(medium only){
//      left: -140px;
//      position: absolute;
//      width: 100vw;
    }
    @include breakpoint(large only){
//      left: -140px;
//      position: absolute;
//      width: 100vw;
    }
  }

}


