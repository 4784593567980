// 入力->確認->完了
$base: $maincolor;
$back: #ffffff;

$triangleSize: 20px 0 20px 25px;
$trianglePosition: -25px;

$triangleSizeS: 17px 0 17px 13px;
$trianglePositionS: -13px;

.form-phase {
  .phase {
    &.thanks {
      border: 1px solid $maincolor;
      background: none;
      width: 80px;
    }
    background: url("../img/path.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;

    line-height: 34px;
    position: relative;
    text-align: center;
    width: 90px;
  }
  display: flex;
  display:-webkit-box;
  display:-webkit-flex;

  justify-content: space-between;
  flex-pack: justify;
  -webkit-box-pack: justify;

}
.mw_wp_form_input {
  .phase.typing {
    background: url("../img/pathR.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
  }
}
.mw_wp_form_confirm {
  .phase.confirm {
    background: url("../img/pathR.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    color: #fff;
  }
}
.mw_wp_form_complete {
  .phase.thanks {
    background: $base;
    color: #fff;
  }
}

input.btn {
  &:hover {
    color: $maincolor;
    background: $white;
  }
  background: $maincolor;
  border-radius: 4px;
  border: 1px solid $maincolor;
  color: $white;
  display: block;
  line-height: 1;
  margin: 0 30px;
  text-align: center;
  padding: 17px 0;
}

.typing {
  z-index: 3;
}
.confirm {
  z-index: 2;
}
.thanks{
  z-index: 1;
}


// 住所自動入力
$backB: #444;
$textB: #ffffff;

.mwform-checkbox-field.horizontal-item+.mwform-checkbox-field.horizontal-item {
  margin-left: 0;
}
.mwform-checkbox-field.horizontal-item {
  margin-right: 15px;
}
.zip {
  input {
    width: 30%;
  }
  #zip {
    &:active {
      background: $backB;
      color: $textB;
    }
    //    @include transition(0.3s linear);
    border: 1px solid $backB;
    background: $textB;
    color: $backB;
    cursor: pointer;
    display: inline-block;
    padding: 3px 10px 5px;
    margin: 5px 0 0;
  }
}


@include breakpoint(large){
  .form-phase {
    .phase {
      &.thanks {
        width: 176px;
      }
      line-height: 40px;
      width: 170px;
    }
  }
}

.form-en .contact {
  .must {
    background: #bb4444;
    color: $white;
    padding: 2px 1em;
  }
}

// input+textarea
.input[type="text"], .input[type="email"] {
  @extend .f12;
  background: #f7f7f7;
  border: 1px solid #cecece;
  padding: 8px 20px;
  line-height: 1;
  margin: 10px 0 0;
  height: auto;
  @include breakpoint(medium){
    width: 100%;
  }
}
textarea.input {
  @extend .f12;
  background: #f7f7f7;
  padding: 2px 20px;
  line-height: 1.6;
  margin: 10px 0 0;
  height: auto;
}
// checkbox or radio
.checkbox {
  @include breakpoint(small){
    label {
      font-size: 1em;
    }
    .mwform-checkbox-field {
      margin: 10px auto 0;
      width: 100%;
    }
  }
  @include breakpoint(medium){
    .mwform-checkbox-field {
      margin: 5px 15px 5px 0;
      width: auto;
    }
  }


  &.square {
    input[type="checkbox"]:checked::before,
    input[type="checkbox"]
    {
      border-radius: 0;
    }
  }
  input[type="checkbox"] {
    &:checked::before {
      background-color: $maincolor;
//      border-radius: 50%;
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #f7f7f7;
//    border-radius: 50%;
    border: solid 1px #cecece;
    margin: 0 .5em 0 0;
    position: relative;
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
.radio {
  input[type="radio"] {
    &:checked::before {
      background-color: #ed959e;
      border-radius: 50%;
      border: solid 1px #ed959e;

      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: 12px;
      width: 12px;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: #fff2f4;
    border-radius: 50%;
    border: solid 1px #cecece;
    margin: 0 .5em 0 0;
    position: relative;
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}

