// foundation
.noframe {
  @include breakpoint(small only){
    .tabs {
      .items {
        margin: 0 auto 10px;
      }
      margin: 0 auto;
      width: 200px;
    }
    .tabs-title > a {
      padding: 6px 0;
      text-align: center;
    }
    .tabs-content {
      border: none;
    }
    .tabs-panel {
      padding: 20px 0;
    }
  }
  @include breakpoint(medium){

  }
}

.tabs-product .toggle-box {
  @include breakpoint(small only){
    .items {
      &:nth-child(odd) {
        margin-right: 4%;
      }
      margin: 0 auto 15px;
      width: 48%;
    }
//    .tabs-title > a {
//      padding: 6px 0;
//      text-align: center;
//    }
//    .tabs-content {
//      border: none;
//    }
//    .tabs-panel {
//      padding: 20px 0;
//    }
  }
  @include breakpoint(medium){
    .tabs-title a {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.tabs {
  background: transparent;
  border: none;
}
.tabs-content {
  .custom990 {
    @include breakpoint(small){
      margin: 0 auto;
      width: 100%;
    }
    @include breakpoint(medium){
      width: 990px;
    }
  }
  &.noframe {
    .inner {
      padding: 0;
    }
    border: none;
  }
  @include breakpoint(small){

  }
  @include breakpoint(medium){
    .inner {
      padding: 0 40px;
    }
  }

  background: $white;
  border: 1px solid $maincolor;

}
.tabs-panel {
  @include breakpoint(small){
    padding: 20px 15px;
  }
  @include breakpoint(medium){
    padding: 33px 0;

  }
}
.tabs-item {
  @include breakpoint(small){
    .list {
      margin-bottom: 15px;
    }
    padding: 0;
  }
  @include breakpoint(medium){
    .list {
      margin-bottom: 30px;
    }
    padding: 0 0 0 40px;
  }

}
.tabs-img {
  line-height: 1;
  margin: 0 0 20px;
}
.tabs-title {
  &.slice-4 { width: 25%; }
  &.slice-3 {
    width: 33.333333%;
  }
  &.slice-2 { width: 50%; }

  @include breakpoint(small only){
    &.slice-1-small {
      width: 100%;
    }
    &.slice-2-small {
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-left: 2%;
      }
      margin-bottom: 15px;
      width: 48%;
    }
  }
}
.tabs-title > a {
  font-size: 1em;
  line-height: 1.2;
  @include breakpoint(small only){
    padding: .7rem 20px;
  }
}


.orbit {
  @include breakpoint(small){
    .orbit-container {
      border: 1px solid $effect;
    }
    .orbit-slide {
      height: 125px;
      width: 100%;
    }
    .orbit-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin:  auto;
      max-width: 100%;
    }
    .orbit-bullets {
      button {
        height: 8px;
        width: 8px;
      }
      margin: 10px 0 0;
    }
    width: 100%;
  }
  @include breakpoint(medium){
    .orbit-slide {
      height: 220px;
      width: 100%;
    }
    width: 323px;
  }
}
// /tabs-content

// wordpress
.markdown {
  table {
    th,td {
      border: 1px solid #dfdfdf;
      padding: .4em 1em;
    }
    th {
      background: #dfdfdf;
    }
    border-collapse: collapse;
    table-layout: fixed;
    @include breakpoint(small only){
      width: 200%;
    }
  }
  .tablewrap {
    overflow: auto;
    width: 100%;
  }
  .acc {
    .acc-button {
      &::after {
        border-style: solid;
        border-width: 8px 8px 0 8px;
        border-color: $maincolor transparent transparent  transparent;

        content: " ";
        margin: 0 15px;
        width: 0;
        height: 0;
      }
      @extend .flex;
      align-items: center;
      cursor: pointer;
      margin: 1em 0 .5em;
      padding: 0 1em;
    }
    .acc-target {
      display: none;
    }
  }
}

// webfontloader
.allclear * {
  color: transparent!important;
}


