.back-manufacturer {
  background: url("../img/lineup.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.back-news {
  background: url("../img/news_back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.top-products {
  .thumb {
    @include breakpoint(small only){
      margin: 0 auto 10px;
      text-align: center;
      width: 100%;
    }
    @include breakpoint(medium){
      text-align: center;
      margin-right: 50px;
    }
  }
  @include breakpoint(small){
    .left-block .back-color {
      &:nth-child(2),&:nth-child(4) {
        background: $gray;
      }
    }
    .right-block .back-color {
      &:nth-child(2) {
        background: $gray;
      }
    }
    .left, .right {
      padding: 20px 20px 10px;
      width: 100%;
    }
  }
  @include breakpoint(medium){
    .right-block .back-color {
      &:nth-child(1), &:nth-child(3) {
        background: $gray;
      }
      &:nth-child(2) {
        background: transparent;
      }
    }
    .left {
      margin: 0 0 0 auto;
      padding: 20px 65px 0 0;
      height: 220px;
    }
    .right {
      .thumb {
        margin-right: 33px;
      }
      margin: 0 auto 0 0;
      padding: 20px 0 0 65px;
      height: 220px;
//      width: $widthDefault/2;
    }
  }
  @include breakpoint(large){
    .left {
      width: $widthDefault/2;
    }
    .right {
      width: $widthDefault/2;
    }
  }
}
.top-about {
  @include breakpoint(small){
    .items:not(:last-child) {
      margin-bottom: 10px;
    }
    padding: 0 20px;
    margin: 40px 0 20px;
    width: 100%;
  }
  @include breakpoint(medium){
    .items:not(:last-child) {
      margin-bottom: 0;
    }
    .items:first-child {
      margin-bottom: 30px;
    }
    margin: 0 65px 0 auto;
    width: 560px;
  }

}

// index
//#loading {
//  .mark {
//    &.visible {
//      opacity: 1;
//    }
//    &.scale {
//      transform: scale(.8);
//      transition: 2s;
//    }
//    position: absolute;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    margin: auto;
//    display: block;
//
//    opacity: 0;
//    transform: scale(1);
//    transition: 1.6s;
//  }
//  &.visible {
//    opacity: 0;
//  }
//  opacity: 1;
//  transition: 1.6s;
//
//  position: fixed;
//  overflow: hidden;
//  overflow-y: scroll;
//  background: url("../img/main_back.png") #FDFCFB;
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center;
//  height: 100vh;
//  width: 100%;
//  z-index: 99;
//  top: 0;
//  left: 0;
//  box-sizing: border-box;
//  padding-bottom: 10px;
//}

$m_height: 120;
$m_height_over: 40;
.main-visual {
  .icons {
    .items {
      line-height: 1;
      margin: 0 10px;
      img {
        max-height: 25px;
      }
      &.active {
        .mono {
          display: none;
        }
        .color {
          display: block;
        }
      }
      .color {
        display: none;
      }
    }
    margin: 0 auto 120px;
  }
  .inner {
    .arrow {
      @include breakpoint(small){
        &.prev {
          background: url("../img/left.png") $maincolor;
          background-repeat: no-repeat;
          background-size: 7px;
          background-position: center;
          left: 0;
          z-index: 2;
        }
        &.next {
          background: url("../img/right.png") $maincolor;
          background-repeat: no-repeat;
          background-size: 7px;
          background-position: center;
          right: 0;
          z-index: 2;
        }
        height: 48px;
        top: 60px;
        position: absolute;
        width: 20px;
      }
      @include breakpoint(medium){
        &.prev {
          background-size: 14px;
        }
        &.next {
          background-size: 14px;
        }
        cursor: pointer;
        height: 96px;
        top: 90px;
        width: 40px;
      }

    }
    overflow: hidden;
    position: relative;
  }

  .left, .right {
    animation-duration: .8s;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    @include breakpoint(medium){
      max-width: 50%;
    }
  }
  .left {
//    width: 460px;
  }

  background: url("../img/main_back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  @include breakpoint(small){
    padding: 20px 0 30px;
  }
  @include breakpoint(medium){
    .btn {
      margin-right: 20px;
    }
    padding: 133px 0 0;
    height: 772px;
  }
}
.main-group {
  .animated.slideOutLeft,.animated.slideOutUp {
    opacity: 0;
    transition: .6s;
  }
  .logo {
    img {
      max-height: 55px;
    }
    margin-bottom: 40px;
  }
  &.active {
    display: block;
  }
  display: none;
  overflow: hidden;

}
.main-product-name {
  color: $maincolor;
  font-size: 12px;
  @include breakpoint(small){
    margin: 8px 0 0;
    text-align: center;
  }
  @include breakpoint(medium){
    margin: 20px 0 0;
    text-align: right;
  }
}
@mixin visual($pagename){
  @include breakpoint(small){
    background: url("../img/main_#{$pagename}_small.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @include breakpoint(medium){
    background: url("../img/main_#{$pagename}.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.sub-visual {
  .company {
    @include visual(company);
  }
  .solutions {
    @include visual(solutions);
  }
  .manufacturer {
    @include visual(manufacturer);
  }
  .profile {
    @include visual(profile);
  }
  .technology {
    @include visual(technology);
  }
  .business {
    @include visual(business);
  }
  .contact, .thanks {
    @include visual(contact);
  }
  .news {
    @include visual(news);
  }
  .maker {
    @include visual(maker);
  }
  .blog {
    @include visual(blog);
  }
  .recruit {
    @include visual(recruit);
  }
  .column {
    @include visual(column);
  }
  .policy {
    @include visual(policy);
  }

  .content {
    @include breakpoint(small){
      height: 125px;
    }
    @include breakpoint(medium){
      height: 240px;
    }
  }

  width: 100%;
}
.sub-search {
  @include breakpoint(small){
    background: #8797cb;
    h1 {
      padding: 12px 20px;
    }
  }
  @include breakpoint(medium){
    h1 {
      height: 92px;
      line-height: 92px;
      padding: 0;
    }
  }


  width: 100%;
}

.toggle-box {
  .tabs-title {
    @include breakpoint(small){
      a {
        background: transparent;
        color: $black;
        border: 1px solid $maincolor;
      }
      &.is-active a {
        background: $maincolor;
        color: $white;
      }
    }
    @include breakpoint(medium){
      a {
        display: block;
        background: #eaeaea;
        border: none;
      }
      &:nth-child(odd) a {
        background: #ccc;
      }
      &.is-active a {
        background: $maincolor;
        box-shadow: 0 -4px 0 $maincolor;
      }
    }

  }
}

.message {
  .right {
    width: 690px;
  }
}

.circleItem {
  &.circle-red {
    background: url("../img/circle_red.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 210px;
  }
  &.circle-green {
    background: url("../img/circle_green.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 210px;
  }
  &.circle-yellow {
    background: url("../img/circle_yellow.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 210px;
  }

  &.circle-red.en {
    background: url("../img/circle_red_en.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 268px;
  }
  &.circle-green.en {
    background: url("../img/circle_green_en.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 268px;
  }
  &.circle-yellow.en {
    background: url("../img/circle_yellow_en.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 268px;
  }
  &.en {
    .left {
      width: 268px;
    }
    .right {
      width: calc(100% - 268px);
    }
  }
  .left {
    text-align: center;
    width: 210px;
  }
  .right {
    padding: 0 40px;
    width: calc(100% - 210px);
  }
  @include breakpoint(small only){
    &.circle-red {
      .left {
        color: #b72c2e;
        margin: 20px auto 10px;
      }
      background: #fee9e9;
      height: auto;
    }
    &.circle-green {
      .left {
        color: #419a26;
        margin: 20px auto 10px;
      }
      background: #ebfce6;
      height: auto;
    }
    &.circle-yellow {
      .left {
        color: #a97512;
        margin: 20px auto 10px;
      }
      background: #fef4df;
      height: auto;
    }
    &.circle-red.en {
      .left {
        color: #b72c2e;
        margin: 20px auto 10px;
      }
      background: #fee9e9;
      height: auto;
    }
    &.circle-green.en {
      .left {
        color: #419a26;
        margin: 20px auto 10px;
      }
      background: #ebfce6;
      height: auto;
    }
    &.circle-yellow.en {
      .left {
        color: #a97512;
        margin: 20px auto 10px;
      }
      background: #fef4df;
      height: auto;
    }

    &.en {
      .left {
        text-align: center;
        width: 100%;
      }
      .right {
        padding: 0 20px;
        width: 100%;
      }
    }
    .left {
      text-align: center;
      width: 100%;
    }
    .right {
      padding: 0 20px;
      width: 100%;
    }
  }
  @include breakpoint(medium){
    &.circle-red {
      .left {
        color: #b72c2e;
      }
    }
    &.circle-green {
      .left {
        color: #419a26;
      }
    }
    &.circle-yellow {
      .left {
        color: #a97512;
      }
    }
  }
}

.sns-right {
  .heading {
    display: inline-block;
  }
  .link {
    &.second {
      right: -6.5em;
      @include breakpoint(small) {
        top: .4em;
        width: 20px;
      }
      @include breakpoint(medium) {
        top: .2em;
        width: 35px;
      }
    }
    top: -.1em;
    right: -4em;
    position: absolute;
    @include breakpoint(small) {
      width: 35px;
    }
    @include breakpoint(medium) {
      width: 50px;
    }
  }
}
.sns-footer {
  margin-top: 15px;
  a {
    margin-right: 30px;
  }
}
// /index


// header
.globalNav {
  @include breakpoint(small only){
    .right {
      background: $maincolor-light;
      color: $black;
      position: fixed;
      top: $headerHsp;
      left: 0;
      height: 100%;
      padding-bottom: 60px;
      overflow: auto;
      width: 100%;
    }
    .active {
      .anchor.grand::after {
        transform: rotate(180deg);
      }
    }
    .single {
      &.overview {
        background: $maincolor-light;
        .anchor {
          color: $maincolor;
          line-height: 20px;
        }
      }
      .anchor {
        &.top {
          margin: 15px 0 0;
          border-top: 1px solid rgba(42, 58, 109, 0.4);
        }
        &.grand::after {
          border-style: solid;
          border-width: 8px 4px 0 4px;
          border-color: $maincolor transparent transparent transparent;
          content: "";
          float: right;
          height: 0;
          width: 0;
        }
        background: $maincolor-light;
        color: $black;
        border-bottom: 1px solid rgba(42, 58, 109, 0.4);
        display: block;
        padding: 15px 20px;
        margin: 0;
        line-height: 1;
        width: 100%;
      }
      text-align: left;
      white-space: nowrap;
      width: 100%;
    }
    height: $headerHsp;
  }
  @include breakpoint(medium){
    .right {
      position: absolute;
      top: 0;
      right: 0;
    }
    .single {
      &.overview {
        background: $maincolor-light;
        .anchor {
          color: $maincolor;
          line-height: 20px;
        }
      }
      .anchor {
        border-left: 1px solid #ccc;
        color: $white;
        cursor: pointer;
        display: block;
        margin: 10px 0;
        padding: 0;
        line-height: 40px;
      }
      height: $headerH;
      white-space: nowrap;
      width: 12.5%;
    }
    height: $headerH;
  }

}


// footer
.bread-small {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.copyright {
  @include breakpoint(small){
    color: $white;
    font-size: 11px;
    line-height: 1;
    text-align: center;
    padding: 13px 0;
  }
  @include breakpoint(medium){
    font-size: 11px;
    padding: 0 0 20px;
  }
}
.footerNav {
  .left {
    width: 186px;
  }
  .right {
    width: calc(100% - 186px);
  }
  .list {
    &.maker {
      margin: 6px 30px 18px 10px;
    }
    margin: 6px 0 18px 10px;
  }
  p {
    margin-bottom: 6px;
  }
  .single {
    margin-bottom: 32px;
  }
  padding: 20px 0 60px;
}
.history {
  .left {
    .age {
      &::after {
        content: " ";
        display: block;
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30px 40px 0 40px;
        border-color: $maincolor transparent transparent transparent;
      }
      background: $maincolor;
      color: $white;
      text-align: center;
      padding: 14px 17px 0;
      margin: 0;
      position: relative;
      height: inherit;
      width: 80px;
      @include breakpoint(small only){
        &::after {
          border-width: 15px 20px 0 20px;
          bottom: -15px;
        }
        padding: 7px 8px 0;
        width: 40px;
      }
    }
    @include breakpoint(small){
      padding: 0 16px 0 0;
      height: 100%;
      width: 20%;
    }
    @include breakpoint(medium){
      padding: 0 0 0 94px;
      width: 25%;
    }
    vertical-align: top;
    display: table-cell;
  }

  @include breakpoint(small){
    .right {
      display: table-cell;
      overflow: hidden;
      width: 80%;
    }
    .rows {
      &:last-child {
        margin: 0;
      }
      margin: 0 0 12px;
    }
  }
  @include breakpoint(medium){
    .right {
      width: 75%;
    }
    .rows {
      margin: 0 0 25px;
    }
  }

  .year {
    color: $maincolor;
    @extend .bold;
    @include breakpoint(small){
      width: 100%;
    }
    @include breakpoint(medium){
      width: 60px;
      & + .month {
        margin-left: 45px;
      }
    }
  }
  .month {
    color: $maincolor;
    @extend .bold;
    @include breakpoint(small){
      width: 28px;
    }
    @include breakpoint(medium){
      margin-left: 105px;
    }
    @include breakpoint(large){
      text-align: right;
      width: 35px;
    }
  }
  .content {
    @include breakpoint(small){
      width: calc(100% - 28px);
    }
    @include breakpoint(medium){
      &.second {
        margin-left: 77px;
      }
      margin-left: 17px;
      width: 570px;
    }
  }


  display: table;
  table-layout: fixed;
  border-bottom: 1px solid #dcdcdc;
  padding: 20px 0 55px;
  height: 100%;
  width: 100%;
  @include breakpoint(small only){
    padding: 20px 0 25px;

  }
}
// /history


.maker-intro {
  .left {
    width: 250px;
    @include breakpoint(small only){
      text-align: center;
      width: 100%;
    }
  }
  .right {
    width: 870px;
    @include breakpoint(small only){
      width: 100%;
    }
  }
  padding-top: 20px;
  padding-bottom: 20px;
}
.makerbox {
  .img {
    float: left;
    margin: 0 40px 18px 0;
  }
}

.maker-btn {
  @include breakpoint(small){
    .li:first-child {
      width: 100%;
    }
    .btn {
      margin: 0 auto 15px;
    }
    justify-content: center;
  }
  @include breakpoint(medium){
    .li:first-child {
      margin-right: 28px;
    }
    .btn {
      margin: 0 40px 0 0;
    }
    justify-content: flex-start;
  }
}
// /maker

.logo-line {
  img {
    vertical-align: middle;
  }
  &.height30 {
    height: 30px;
    line-height: 30px;
  }
  &.height38 {
    height: 38px;
    line-height: 38px;
  }
  &.height50 {
    height: 50px;
    line-height: 50px;
  }
}
.solution {

}
// /solution
.column-categories-child {
  a {
    color: $green;
    &:hover {
      text-decoration: underline;
    }
  }
  .items {
    line-height: 1;
    @include breakpoint(medium){
      margin-bottom: 10px;
    }
  }
  background: #f6fff4;
  padding: 15px 20px;
}
// /column

.search {
  &.results {
    .single {
      @include breakpoint(small){
        padding: 10px 0 15px;
        margin: 15px 0 0;
      }
      @include breakpoint(medium){
        padding: 0 0 20px 20px;
        margin: 30px 0 0;
      }
      border-bottom: 1px solid $light-gray;
    }
    width: 840px;
  }
  &.content {
    width: 820px;
  }
  .search-data {
    .right {
      padding-top: 5px;
    }
    @include breakpoint(small){

    }
    @include breakpoint(medium){
      padding-left: 20px;
    }
    overflow: hidden;
  }
  .results-title {
    .title {
      padding: 30px 0 15px;
    }
    width: 460px;
    @include breakpoint(small only){
      .right {
        float: right;
      }
      .left {
        float: left;
      }
      .title {
        padding: 10px 0;
      }
      width: 100%;
    }
  }
}
.results-content {
  .thumb {
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: auto;
    }
    position: relative;
    border: 1px solid $effect;
    height: 150px;
    width: 220px;

    @include breakpoint(small only){
      margin: 0 auto 10px;
    }
  }
  .info {
    width: 520px;
  }
  .arrow {
    &::before {
      color: $black;
      content: "〉";
      font-size: 40px;
    }
    display: block;
    height: 37px;
    line-height: 37px;
    width: 20px;
  }
}


.product-slides {
  @include breakpoint(small){
    .items {
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: auto;
      }
      position: relative;
      width: 100%;
      min-height: 148px;
    }
    border: 1px solid $effect;
    height: 150px;
    width: 280px;
  }
  @include breakpoint(medium){
    .items {
      height: 218px;
    }
    height: 220px;
    width: 323px;
  }

}

.search-sidebar {
  .cat-grand {
    border-top: 1px solid $effect;
    border-bottom: 1px solid $effect;
    background: $maincolor-light;
    padding: 12px 20px;
    line-height: 1;
    margin: 0;
  }
  .cat-parent {
    &.server {
      letter-spacing: -1.7px;
    }
    &.top {
      border-top: 1px solid $effect;
    }
    border-bottom: 1px solid $effect;

    padding: 12px 0 12px 30px;
    margin: 0;
  }
  .cat-child {
    &:not(:last-child) {
      border-bottom: 1px solid #f5f5f5;
    }
    padding: 8px 0 8px 30px;
    margin: 0;
  }
  .toggle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: $maincolor transparent transparent  transparent;

    cursor: pointer;
    float: right;
    margin: .6em 5px .6em 0;
    width: 0;
    height: 0;
    display: block;
  }
  width: 262px;
}

// /search


.en-company-link {
  .btn {
    @include breakpoint(medium){
      margin: 0 30px;
      width: 280px;
    }
  }
}

// /en
