// preset
a {
  transition: 0.3s ease;
}
img,svg {
  vertical-align: top;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
// /preset

@font-face {
  font-family: "quick-cjk";
  src: url("../font/NotoSansCJKjp-Regular.otf");
  src: url("../font/NotoSansCJKjp-Regular.woff2") format("woff2"),
    url("../font/NotoSansCJKjp-Regular.woff") format("woff"),
    url("../font/NotoSansCJKjp-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "quick-cjk";
  src: url("../font/NotoSansCJKjp-bold.otf");
  src: url("../font/NotoSansCJKjp-bold.woff2") format("woff2"),
    url("../font/NotoSansCJKjp-bold.woff") format("woff"),
    url("../font/NotoSansCJKjp-bold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "quick-cjk";
  src: url("../font/NotoSansCJKjp-black.otf");
  src: url("../font/NotoSansCJKjp-black.woff2") format("woff2"),
    url("../font/NotoSansCJKjp-black.woff") format("woff"),
    url("../font/NotoSansCJKjp-black.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

// template
body {
  color: $black;
  font-family: 'Noto Sans Japanese','Noto Sans JP', sans-serif;
//  font-family: 'quick-cjk','Noto Sans Japanese','Noto Sans JP', sans-serif;
  //  font-family: 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'MS Pゴシック', 'MS PGothic', sans-serif;
  font-weight: normal;

  @include breakpoint(small){
    font-size: 12px;
  }
  @include breakpoint(medium){
    font-size: 12px;
  }
  @include breakpoint(large){
    font-size: 16px;
  }


  line-height: 2;
  margin: auto;
  -webkit-text-size-adjust: 100%;
}

@include breakpoint(medium only){
  html, body, header, .megamenu-frame {
    min-width: 1300px!important;
  }
}
@include breakpoint(large only){
  html, body, header, .megamenu-frame {
    min-width: 1300px!important;
  }
}
@media screen and (min-width:64em) and (max-width:1300px) {
  html, body, header, .megamenu-frame {
    min-width: 1300px!important;
  }
}

